<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div class="rounded-lg px-3 py-8">
                  <p class="text-center">
                    <v-chip>
                      <v-icon left> mdi-account-supervisor-circle </v-icon>
                      <p class="mb-0 font-weight-bold">Asistencia</p>
                    </v-chip>
                  </p>
                  <p class="mb-0 text-h5 font-weight-bold text-center">
                    {{ attendance.stringGroup }}
                  </p>
                  <p class="mb-0 text-subtitle-1 font-weight-bold text-center">
                    <span class="text-lowercase">{{ formattedDate }}</span>
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-row class="mx-auto">
              <v-col cols="12" xl="10" class="mx-auto d-flex align-center">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                  class="mx-5"
                  filled
                  rounded
                ></v-text-field>
                <v-switch
                  v-model="disabledSelect"
                  flat
                  :label="`Habilitar edición`"
                  class="mx-5"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xl="10" class="mx-auto">
                <v-data-table
                  :headers="filteredHeaders"
                  :items="attendance.data"
                  :search="search"
                  :items-per-page="100"
                  dense
                  hide-default-footer
                  show-group-by
                  sort-by="full_name"
                  :group-by="tableManage"
                >
                  <template
                    v-if="this.schedule.isTechnical"
                    v-slot:[`group.header`]="{ group, headers, toggle, isOpen }"
                  >
                    <td :colspan="headers.length">
                      <v-btn
                        @click="toggle"
                        small
                        icon
                        :ref="group"
                        :data-open="isOpen"
                      >
                        <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                        <v-icon v-else>mdi-chevron-down</v-icon>
                      </v-btn>
                      <v-icon small dark left color="indigo darken-2"
                        >mdi-account-group</v-icon
                      >
                      <span
                        class="indigo--text text--darken-2 font-weight-bold"
                        >{{ group }}</span
                      >
                    </td>
                  </template>
                  <template
                    v-else
                    v-slot:[`group.header`]="{ group, headers, toggle, isOpen }"
                  >
                    <td :colspan="headers.length">
                      <v-btn
                        @click="toggle"
                        small
                        icon
                        :ref="group"
                        :data-open="isOpen"
                      >
                        <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                        <v-icon v-else>mdi-chevron-down</v-icon>
                      </v-btn>
                      <v-icon small dark left color="blue"
                        >mdi-account-group</v-icon
                      >
                      <span class="blue--text font-weight-bold">{{
                        group
                      }}</span>
                    </td>
                  </template>
                  <template v-slot:[`item.photo`]="{ item }">
                    <v-avatar size="70" class="my-3">
                      <v-img :src="item.photo"></v-img>
                    </v-avatar>
                  </template>
                  <template v-slot:[`item.code`]="{ item }">
                    <p class="text-body-1 mb-0 font-weight-bold">
                      {{ item.code }}
                    </p>
                  </template>
                  <template v-slot:[`item.full_name`]="{ item }">
                    <p class="text-body-2 mb-0 font-weight-medium">
                      {{ item.full_name }}
                    </p>
                  </template>
                  <template v-slot:[`item.attendance_status_id`]="{ item }">
                    <v-select
                      v-model="item.attendance_status_id"
                      :items="filteredAttendanceOptions"
                      item-text="name"
                      item-value="id"
                      filled
                      rounded
                      dense
                      :disabled="!disabledSelect"
                      @change="updateAttendanceStatus(item)"
                      class="my-3"
                    >
                      <template v-slot:selection="{ item }">
                        <v-chip
                          color="green darken-3"
                          class="white--text"
                          small
                          v-if="item.id == 1"
                        >
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <v-chip
                          color="red darken-3"
                          class="white--text"
                          small
                          v-if="item.id == 2"
                        >
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <v-chip
                          color="orange darken-2"
                          class="white--text"
                          small
                          v-if="item.id == 3"
                        >
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <v-chip
                          color="blue-grey darken-4"
                          class="white--text"
                          small
                          v-if="item.id == 4"
                        >
                          <span>{{ item.name }}</span>
                        </v-chip>
                      </template>
                    </v-select>
                  </template>
                  <template v-slot:[`item.is_justified`]="{ item }">
                    <v-chip
                      v-if="item.is_justified"
                      class="ma-2 text-caption"
                      color="green lighten-1"
                      text-color="white"
                    >
                      <v-icon left> mdi-check-circle </v-icon>
                      Justificado
                    </v-chip>
                    <v-chip
                      v-if="
                        item.is_justified === null &&
                        (item.attendance_status_id == 2 ||
                          item.attendance_status_id == 3)
                      "
                      class="ma-2 text-caption"
                      color="blue-grey lighten-1"
                      text-color="white"
                    >
                      <v-icon left> mdi-close-circle </v-icon>
                      No justificado
                    </v-chip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import attendanceStatusRepository from "../../../repositories/attendanceStatusRepository";
import studentClassRepository from "../../../repositories/studentClassRepository";
import { mapGetters } from "vuex";
export default {
  name: "AttendanceRecordModal",
  props: {
    schedule: {
      type: Object,
    },
    attendance: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      search: "",
      attendanceStatus: [],
      disabledSelect: false,
    };
  },
  mounted() {
    this.getAttendanceStatus();
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
    getAttendanceStatus() {
      attendanceStatusRepository
        .getAllAttendanceStatus()
        .then((data) => {
          this.attendanceStatus = data.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
    updateAttendanceStatus(item) {
      studentClassRepository
        .updateStudentClass(item.studentClassId, item)
        .then((data) => {
          this.setLog(item, item.studentClassId);
          this.fireToast({
            icon: "success",
            title: "Registro modificado sactisfactoriamente",
          });
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Error al intentar modificar el registro",
          });
        });
    },
    setLog(attendance, id) {
      const log = {
        user_id: this.currentUserPersonalInfo.id_user,
        action: "UPDATE",
        description: `Modificó asistencia al alumno con código ${attendance.code}`,
        table_groupable_type: "StudentClass",
        table_groupable_uuid: id,
      };
      this.addLog(log);
    },
    close() {
      this.disabledSelect = false;
      this.toggleModal();
    },
  },
  computed: {
    ...mapGetters(["currentPageActions", "currentUserPersonalInfo"]),
    filteredHeaders() {
      if (this.schedule.isTechnical) {
        return [
          { text: "Foto", value: "photo", groupable: false },
          { text: "Código", value: "code", groupable: false },
          { text: "Nombre completo", value: "full_name", groupable: false },
          { text: "Sección", value: "section_group" },
          {
            text: "Estado",
            value: "attendance_status_id",
            width: "150px",
            groupable: false,
          },
          { text: "", value: "is_justified", groupable: false },
        ];
      } else {
        return [
          { text: "Foto", value: "photo", groupable: false },
          { text: "Código", value: "code", groupable: false },
          { text: "Nombre completo", value: "full_name", groupable: false },
          { text: "Especialidad", value: "technical_group", groupable: false },
          {
            text: "Estado",
            value: "attendance_status_id",
            width: "150px",
            groupable: false,
          },
          { text: "", value: "is_justified", groupable: false },
        ];
      }
    },
    filteredAttendanceOptions() {
      return this.attendanceStatus.data.filter((element) => element.id !== 5);
    },
    tableManage() {
      return this.schedule.isTechnical ? "section_group" : "technical_group";
    },
    formattedDate() {
      if (this.attendance.dateAttendance != undefined) {
        const myDate = new Date(
          this.attendance.dateAttendance + "T24:00:00.000Z"
        ); // Add "T00:00:00.000Z" to the date string
        const formatter = new Intl.DateTimeFormat("es-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          weekday: "long",
        });
        const fullDate = formatter.format(myDate);
        return fullDate;
      }
    },
  },
  watch: {
    dialog(newValue, oldValue) {
      if (newValue) {
        this.getAttendanceStatus();
      }
    },
  },
};
</script>
<style>
.v-text-field__details {
  display: none !important;
}
</style>
